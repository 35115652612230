import React from "react";
import Meta from "../../global/Meta";
import AccommodationSlider from "./AccommodationSlider";
import "./Accommodation.scss";

import { NormalLink, CustomLink1 } from "../../global/CustomLinks";
import { bookingLink } from "../../global/constants";

import {
  faBed,
  faUser,
  faWifi,
  faBicycle,
  faBath,
  faTv,
  faUtensils,
  faWheelchair,
  faCouch,
  faTshirt,
  faParking
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Accommodation = () => (
  <div className="accommodation">
    <Meta
      title="Affordable Apartments in Queenstown| Full-Serviced Apartments"
      desc="Explore our selection of affordable apartments in Queenstown, offering full-serviced apartments for a comfortable stay. Book now for the best deals!"
      keywords="Apartments in Queenstown, Queenstown apartments"
    />
    <h1>Budget Accommodation Option in Queenstown NZ - Amity Apartments</h1>
    <div className="header-inside">
      <img src="https://d2057z2iq79qyw.cloudfront.net/amity/accommodation.jpg" alt="Queenstown accommodation banner" />
      <div className="caption">
        <div className="caption-inner">
          <h2>Accommodation</h2>
          <p>
            For sleep as good as home. Choose any of our comfortable and well
            looked after apartments
          </p>
        </div>
      </div>
    </div>
    <section className="grey">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6">
            <AccommodationSlider content='["https://d2057z2iq79qyw.cloudfront.net/amity/1bedroom-apt.jpg", "https://d2057z2iq79qyw.cloudfront.net/amity/1bed-room.jpg"]' />
          </div>
          <div className="col-12 col-lg-6">
            <h3>One Bedroom Apartments</h3>

            <p className="subtitle">
              Maximum occupancy is 3 guests, approximate size 50sqm+ .
            </p>
            <p>
              With one Queen bed and one Single bed available in each room, this
              choice is ideal for a couple or small families. Our warm and comfy
              beds are a treat, with no room for disappointment with our
              spacious living space and modern rooms.
            </p>
            <p className="room-type-large-icon">
              <FontAwesomeIcon icon={faBed} /> Queen Bed and Single Bed
            </p>
            <p className="room-type-large-icon">
              <FontAwesomeIcon icon={faUser} /> 2 - 3 Adults
            </p>
            <hr />
            <ul className="facilities-icon">
              <li>
                <FontAwesomeIcon icon={faCouch} />
              </li>
              <li>
                <FontAwesomeIcon icon={faUtensils} />
              </li>
              <li>
                <FontAwesomeIcon icon={faWifi} />
              </li>
              <li>
                <FontAwesomeIcon icon={faBicycle} />
              </li>
              <li>
                <FontAwesomeIcon icon={faBath} />
              </li>
              <li>
                <FontAwesomeIcon icon={faTv} />
              </li>
              <li>
                <FontAwesomeIcon icon={faWheelchair} />
              </li>
              <li>
                <FontAwesomeIcon icon={faTshirt} />
              </li>
              <li>
                <FontAwesomeIcon icon={faParking} />
              </li>
            </ul>

            <NormalLink
              type="btn book-now"
              rel="nofollow"
              href={bookingLink}
              text="Book Now"
            />
          </div>
        </div>
      </div>
    </section>
    <section className="white">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6">
            <h3>
              One Bedroom Apartments<span>with mountain view</span>
            </h3>
            <p className="subtitle">
              Maximum occupancy is 3 guests, approximate size 50sqm+ .
            </p>
            <p>
              Wake up to spectacular views of The Remarkables and stunning views
              of Ben Lomond all in the comfort of your apartment, allowing you
              to experience tranquility and peace like never before. With one
              Queen bed and one Single bed, enjoy your home away from home with
              a complete kitchenette, a spacious living space and dining area,
              unlimited free Wifi.
            </p>
            <p className="room-type-large-icon">
              <FontAwesomeIcon icon={faBed} /> Queen Bed and Single Bed
            </p>
            <p className="room-type-large-icon">
              <FontAwesomeIcon icon={faUser} /> 2 - 3 Adults
            </p>
            <hr />
            <ul className="facilities-icon">
              <li>
                <FontAwesomeIcon icon={faCouch} />
              </li>
              <li>
                <FontAwesomeIcon icon={faUtensils} />
              </li>
              <li>
                <FontAwesomeIcon icon={faWifi} />
              </li>
              <li>
                <FontAwesomeIcon icon={faBicycle} />
              </li>
              <li>
                <FontAwesomeIcon icon={faBath} />
              </li>
              <li>
                <FontAwesomeIcon icon={faTv} />
              </li>
              <li>
                <FontAwesomeIcon icon={faWheelchair} />
              </li>
              <li>
                <FontAwesomeIcon icon={faTshirt} />
              </li>
              <li>
                <FontAwesomeIcon icon={faParking} />
              </li>
            </ul>

            <NormalLink
              type="btn book-now"
              rel="nofollow"
              href={bookingLink}
              text="Book Now"
            />
          </div>
          <div className="col-12 col-lg-6 order">
            <AccommodationSlider content='["https://d2057z2iq79qyw.cloudfront.net/amity/1bedroom-apt1.jpg", "https://d2057z2iq79qyw.cloudfront.net/amity/2bed-hill.jpg"]' />
          </div>
        </div>
      </div>
    </section>
    <section className="grey">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6">
            <AccommodationSlider content='["https://d2057z2iq79qyw.cloudfront.net/amity/2bed-apt.jpg", "https://d2057z2iq79qyw.cloudfront.net/amity/2bed-room.jpg", "https://d2057z2iq79qyw.cloudfront.net/amity/2bedroom-apt.jpg"]' />
          </div>
          <div className="col-12 col-lg-6">
            <h3>Two Bedroom Apartments</h3>

            <p className="subtitle">
              Maximum occupancy is 4 guests, approximate size 50sqm+.
            </p>
            <p>
              Incredible views of Ben Lomond compliment this two bedroom
              apartment. The apartments are situated on the ground floor,
              allowing for easy access. Enjoy a private backyard exclusive to
              you and your friends/family, where you can enjoy time together
              with a warm cup of coffee. Enjoy a complete Kitchenette, and a
              dining and living area.
            </p>
            <p className="room-type-large-icon">
              <FontAwesomeIcon icon={faBed} /> Queen Bed and Two Single Beds
            </p>
            <p className="room-type-large-icon">
              <FontAwesomeIcon icon={faUser} /> 3 - 4 Adults
            </p>
            <hr />
            <ul className="facilities-icon">
              <li>
                <FontAwesomeIcon icon={faCouch} />
              </li>
              <li>
                <FontAwesomeIcon icon={faUtensils} />
              </li>
              <li>
                <FontAwesomeIcon icon={faWifi} />
              </li>
              <li>
                <FontAwesomeIcon icon={faBicycle} />
              </li>
              <li>
                <FontAwesomeIcon icon={faBath} />
              </li>
              <li>
                <FontAwesomeIcon icon={faTv} />
              </li>
              <li>
                <FontAwesomeIcon icon={faWheelchair} />
              </li>
              <li>
                <FontAwesomeIcon icon={faTshirt} />
              </li>
              <li>
                <FontAwesomeIcon icon={faParking} />
              </li>
            </ul>

            <NormalLink
              type="btn book-now"
              rel="nofollow"
              href={bookingLink}
              text="Book Now"
            />
          </div>
        </div>
      </div>
    </section>
    <section className="white">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6">
            <h3>
              Two Bedroom Apartments<span>with mountain view</span>
            </h3>
            <p className="subtitle">
              Maximum occupancy is 4 guests, approximate size 50sqm+.
            </p>
            <p>
              With views of The Remarkables and Ben Lomond on both sides, this
              lovely apartment comes with different bedding configurations One queen bed in main room and Two single beds in the other room or Two queen beds in each room -
              perfect for larger groups. Enjoy all the space you need to feel at
              home with a kitchenette to cater for cooking with the family, and
              a dining and living area. Enjoy the vast amenities that these
              apartments have to offer, like your own private backyard for you
              to relax and enjoy a quiet time with your loved ones.
            </p>
            <p className="room-type-large-icon">
              <FontAwesomeIcon icon={faBed} /> Queen Bed and Two Single Beds
            </p>
            <p className="room-type-large-icon">
              <FontAwesomeIcon icon={faUser} /> 3 - 4 Adults
            </p>
            <hr />
            <ul className="facilities-icon">
              <li>
                <FontAwesomeIcon icon={faCouch} />
              </li>
              <li>
                <FontAwesomeIcon icon={faUtensils} />
              </li>
              <li>
                <FontAwesomeIcon icon={faWifi} />
              </li>
              <li>
                <FontAwesomeIcon icon={faBicycle} />
              </li>
              <li>
                <FontAwesomeIcon icon={faBath} />
              </li>
              <li>
                <FontAwesomeIcon icon={faTv} />
              </li>
              <li>
                <FontAwesomeIcon icon={faWheelchair} />
              </li>
              <li>
                <FontAwesomeIcon icon={faTshirt} />
              </li>
              <li>
                <FontAwesomeIcon icon={faParking} />
              </li>
            </ul>

            <NormalLink
              type="btn book-now"
              rel="nofollow"
              href={bookingLink}
              text="Book Now"
            />
          </div>
          <div className="col-12 col-lg-6 order">
            <AccommodationSlider content='["https://d2057z2iq79qyw.cloudfront.net/amity/mountainview1 (2).jpg", "https://d2057z2iq79qyw.cloudfront.net/amity/mountain-view1.jpg", "https://d2057z2iq79qyw.cloudfront.net/amity/neighboue-2bed.jpg"]' />
          </div>
        </div>
      </div>
    </section>
   
  </div>
);

export default Accommodation;
